.textarea-block {
  position: relative;
}

.textarea-block + .textarea-block {
  margin-top: 1.4rem;
}

.textarea-block label {
  font-size: 1.4rem;
}

.textarea-block textarea {
  width: 100%;
  height: 16rem;
  min-height: 8rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 2px solid var(--color-line-in-white);
  outline: 0;
  resize: vertical;
  padding: 1.2rem 1.6rem;
  font: 1.6rem Archivo;
}

.textarea-block textarea:focus {
  border: 2px solid var(--color-primary-light);
}
